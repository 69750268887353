import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Overlay } from '@angular/cdk/overlay';
import { ZipService } from '../zip.service';
import {StateService} from '../state.service';
import { PageTitleService} from '../page-title.service';
import { GoogleAnalyticsService } from '../google-analytics.service';
import * as moment from 'moment';
import {PostalCode} from '../postal-code';
import {PostalCodesService} from '../postal-codes.service';
import {BackendService} from '../backend.service';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  currentPageTitle = 'RESERVATION ご予約・お問い合わせ ';
  isProcessing = false;
  constructor(
    private builder: FormBuilder,
    private zip: ZipService,
    private snackBar: MatSnackBar,
    private overlay: Overlay,
    private stateService: StateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private pageTitle: PageTitleService,
    private postalCodesService: PostalCodesService,
    private backendService: BackendService,
  ) {
    this.stateService.isVisibleBreadcrumb = true;
    this.stateService.currentPage = true;
    this.stateService.currentPageName = this.currentPageTitle;
    this.pageTitle.changeTitle(this.currentPageTitle);
  }
  spinner = this.overlay.create({
    hasBackdrop: true,
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });
  minDate = new Date(2025, 3, 1);
  durationInSeconds = 3;
  sendSuccess = false;
  timeTable = [
    {
      id: '12:00〜12:30',
    },
    {
      id: '12:30〜13:00',
    },
    {
      id: '13:00〜13:30',
    },
    {
      id: '13:30〜14:00',
    },
    {
      id: '14:00〜14:30',
    },
    {
      id: '14:30〜15:00',
    },
    {
      id: '15:00〜15:30',
    },
    {
      id: '15:30〜16:00',
    },
    {
      id: '16:00〜16:30',
    },
    {
      id: '16:30〜17:00',
    },
    {
      id: '17:30〜18:00',
    },
    {
      id: '18:00〜18:30',
    },
    {
      id: '18:30〜19:00',
    },
    {
      id: '19:00〜19:30',
    },
    {
      id: '19:30〜20:00',
    },
    {
      id: '20:00〜20:30',
    },
    {
      id: '21:00〜21:30',
    },
    {
      id: '21:30〜22:00',
    }
  ];

  // 問いあわせか予約か
  isReserve = true;
  formType = new FormControl('',
    [
      Validators.required,
    ]);
  // 名前
  guestName = new FormControl('',
    [
      Validators.required,
      Validators.maxLength(16)
    ]);
  // ご住所
  zipCode = new FormControl('',
    [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(7)
    ]);
  address =  new FormControl('',
    [
      Validators.required,
    ]);
  // メールアドレス
  email = new FormControl('',
    [
      Validators.required,
      Validators.email
    ]);
  // ご連絡先電話番号
  phoneNumber = new FormControl('', [ Validators.pattern('^0\\d{9,11}$')]);
  // ご予約希望日
  reserveDate = new FormControl(new Date().toLocaleDateString(), [ Validators.required ]);
  // 宿泊日数
  days = new FormControl('', [Validators.required]);
  // 到着予定時刻
  arrivalTime = new FormControl('',  [ Validators.required ]);
  // コテージ利用
  cottage = new FormControl('',
    [
      Validators.required,
    ]);
  numOfGuestsAdultWithoutMembership = new FormControl('', []);
  // 一般人数 小人
  numOfGuestsChildrenWithoutMembership = new FormControl('', []);
  // お問い合わせ内容
  inquiryBody = new FormControl('', []);
  // ペットありなし
  withPets = false;
  withPetsAnswer = new FormControl('',
    [
      Validators.required,
    ]);
  numOfDogSmall = new FormControl('', []);
  numOfDogMid = new FormControl('', []);
  numOfDogLarge = new FormControl('', []);
  infoOfPets = new FormControl('', []);
  inquiryForm = this.builder.group({
    formType: this.formType,
    guestName: this.guestName,
    zipCode: this.zipCode,
    address: this.address,
    email: this.email,
    phoneNumber: this.phoneNumber,
    reserveDate: this.reserveDate,
    days: this.days,
    arrivalTime: this.arrivalTime,
    cottage: this.cottage,
    numOfGuestsAdultWithoutMembership: this.numOfGuestsAdultWithoutMembership,
    numOfGuestsChildrenWithoutMembership: this.numOfGuestsChildrenWithoutMembership,
    withPetsAnswer: this.withPetsAnswer,
    numOfDogSmall: this.numOfDogSmall,
    numOfDogMid: this.numOfDogMid,
    numOfDogLarge: this.numOfDogLarge,
    infoOfPets: this.infoOfPets,
    inquiryBody: this.inquiryBody
  });
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.formType.setValue('ご予約');
    this.withPetsAnswer.setValue('ペットなし');
    // this.changeIsNotReserve();
    this.initNumberInput();
  }
  submit() {
    if (this.inquiryForm.valid) {
      this.isProcessing = true;
      if (this.inquiryForm.value.formType === 'ご予約') {
        moment.locale('JA');
        const reserveDateJa = moment(this.reserveDate.value.toISOString()).format('LL');
        this.reserveDate.setValue(reserveDateJa);
      }
      // メール送信をbanto3経由に変更（IPアドレス制限でSendGrid <- firebase functionsが使えなくなったため
      this.backendService.post('email/email-sender-lake/send-email', {data: this.inquiryForm.value})
        .then(() => {
          this.isProcessing = false;
          this.sendSuccess = true;
          this.showSnackBar('送信しました。');
          let eventName = 'inquiry';
          if (this.inquiryForm.value.formType === 'ご予約') {
            eventName = 'reserve';
          }
          this.googleAnalyticsService.sendEvent( eventName, 'conversion', 'send', 'form' );
        })
        .catch( () => {
          this.showSnackBar('送信失敗しました。');
          this.isProcessing = false;
      });
    } else {
      this.showSnackBar('未入力の項目があります。');
    }
  }
  changeIsReserve() {
    this.isReserve = true;
    // 必須をつける
    this.zipCode.setValidators([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7)
      ]
    );
    this.address.setValidators([ Validators.required ]);
    this.reserveDate.setValidators([ Validators.required ]);
    this.days.setValidators([ Validators.required ]);
    this.arrivalTime.setValidators([ Validators.required ]);
    this.cottage.setValidators([ Validators.required ]);
    this.numOfGuestsAdultWithoutMembership.setValidators([ Validators.required ]);
    this.numOfGuestsChildrenWithoutMembership.setValidators([ Validators.required ]);
    this.withPetsAnswer.setValidators([ Validators.required ]);
    this.numOfDogSmall.setValidators([ Validators.required ]);
    this.numOfDogMid.setValidators([ Validators.required ]);
    this.numOfDogLarge.setValidators([ Validators.required ]);
    this.resetForm();
    this.initNumberInput();
    this.withPetsAnswer.setValue('ペットなし');
  }
  resetForm() {
    this.zipCode.reset();
    this.address.reset();
    this.reserveDate.reset();
    this.days.reset();
    this.arrivalTime.reset();
    this.cottage.reset();
    this.numOfGuestsAdultWithoutMembership.reset();
    this.numOfGuestsChildrenWithoutMembership.reset();
    this.withPetsAnswer.reset();
    this.numOfDogSmall.reset();
    this.numOfDogMid.reset();
    this.numOfDogLarge.reset();
  }
  initNumberInput() {
    this.days.setValue(0);
    this.numOfGuestsAdultWithoutMembership.setValue(0);
    this.numOfGuestsChildrenWithoutMembership.setValue(0);
    this.numOfDogSmall.setValue(0);
    this.numOfDogMid.setValue(0);
    this.numOfDogLarge.setValue(0);
  }
  changeIsNotReserve() {
    this.isReserve = false;
    // 必須を外す
    this.zipCode.clearValidators();
    this.address.clearValidators();
    this.reserveDate.clearValidators();
    this.days.clearValidators();
    this.arrivalTime.clearValidators();
    this.cottage.clearValidators();
    this.numOfGuestsAdultWithoutMembership.clearValidators();
    this.numOfGuestsChildrenWithoutMembership.clearValidators();
    this.withPetsAnswer.clearValidators();
    this.numOfDogSmall.clearValidators();
    this.numOfDogMid.clearValidators();
    this.numOfDogLarge.clearValidators();
    this.resetForm();
  }
  changeWithPets() {
    this.withPets = true;
  }
  changeWithoutPets() {
    this.withPets = false;
  }
  showSnackBar(message) {
    this.snackBar.open(message, 'close', {
      duration: this.durationInSeconds * 1000,
    });
  }
  /*
 * 郵便番号
 */
  updatePostalCode(): void {
    if (this.zipCode.value.length === 7) {
      this.postalCodesService.fetch(this.zipCode.value)
        .then((res: PostalCode[]) => {
          this.address.setValue(res[0]?.pref + res[0]?.address1 + res[0]?.address2 + res[0]?.address3 + ' ' + res[0]?.bizName);
        })
        .catch((e) => console.log(e));
    }
  }
}
