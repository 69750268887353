<!--<app-notice></app-notice>-->
<div class="top-contents">
  <div class="top">
    <div class="top__wrap">
      <div class="top__slider">
        <img src="assets/images/slide/lake-silider.jpg">
      </div>
      <div class="top__slider">
        <img src="assets/images/slide/lake-silider.jpg">
      </div>
    </div>
    <div class="top__logo fade-in-logo">
      <img src="../../assets/images/logo_white.png" alt="レークサイド山の家">
    </div>
    <div class="top__button fade-in-button" routerLink="/reservation">
      ご予約・お問い合わせ
    </div>
  </div>
</div>
<app-menu></app-menu>
<div class="top-contents-main">

  <!--<div class="title-box">
    <h2>レークサイド山の家 臨時休業のお知らせ</h2>
    <div class="subtitle"></div>
  </div>
  <div class="headline">

    いつもレークサイド山の家をご利用頂きましてありがとうございます。<br>
    冬季の営業時におきまして厳寒のため設備の故障が発生致しました。<br>
    従いまして４月より暫くの間休業させて頂き改修工事を致します。<br>
    大変申し訳ございませんが営業再開までしばらくお待ち下さいますよう
    お願い申し上げます。<br>なお営業再開のお知らせは本サイトにてご報告
    させて頂きます。<br>
    <br>
    レークサイド山の家
  </div>-->


  <div class="title-box">
    <h2>NATURE＆HISTORY</h2>
    <div class="subtitle">自然と歴史</div>
  </div>
  <div class="headline">
    開設以来ここには都会の日常とは隔絶した時が流れています。<br>
    日々変わる幻想的な風景を目の当たりにしながら1日をお過ごし頂けます。<br>
    カヌーやボートでの自然体験をする、ヒメマス漁の解禁日には１日中釣りに没頭するなど楽しみ方はあなた次第。
    また当施設はペットと泊まれるお宿としても有名です。<br>
    ご家族同様のペットとともに十和田湖の雄大な自然の中でごゆっくりお過ごしください。
  </div>
  <div class="title-box">
    <h2>ペット一緒に泊まれます</h2>
  </div>
  <div class="image-box">
    <img src="../../assets/images/about/dogs.jpg" alt="ペットと一緒に宿泊">
  </div>
  <div class="headline">
  </div>
  <div class="button mb5" routerLink="/rate">ペット宿泊プラン</div>
  <div class="title-box">
    <h2>TOPICS</h2>
    <div class="subtitle">お知らせやイベント</div>
  </div>
  <app-twitter class="twitter"></app-twitter>
  <div class="button mb5" routerLink="/topics">もっと見る</div>

  <div class="title-box">
    <h2>ACCESS</h2>
    <div class="subtitle">交通のご案内</div>
  </div>
  <app-map></app-map>
  <div class="headline">
    レークサイド 山の家<br>
    〒018-5511秋田県鹿角郡小坂町十和田湖字銀山1-7<br>
    TEL 0176-75-2552<br>
  </div>
  <div class="button mb10" routerLink="/access">もっと詳しく</div>
</div>
